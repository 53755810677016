<template>
    <div class="DeviceDetail">
        <el-card class="box-card">
            <i class="el-icon-close cha" @click="$router.go(-1)"></i>

            <div class="clearfix">
                基本信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">编号：</div>
                    <div class="info">{{ auxilaryInfo.id }}</div>
                </div>
                <div class="item">
                    <div class="label">条码/标签：</div>
                    <div class="info">{{ auxilaryInfo.auxCode }}</div>
                </div>
                <div class="item">
                    <div class="label">工具分类：</div>
                    <div class="info">{{ auxilaryInfo.className }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">工具名称：</div>
                    <div class="info">{{ auxilaryInfo.auxName }}</div>
                </div>
                <div class="item">
                    <div class="label">工具规格型号：</div>
                    <div class="info">{{ auxilaryInfo.auxType }}</div>
                </div>
                <div class="item">
                    <div class="label">工具编号:</div>
                    <div class="info">{{ auxilaryInfo.auxNum }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">保质期/有效期(年)：</div>
                    <div class="info">{{ auxilaryInfo.expirationDate }}</div>
                </div>
                <div class="item">
                    <div class="label">出厂日期：</div>
                    <div class="info">{{ auxilaryInfo.produceDate }}</div>
                </div>
                <div class="item">
                    <div class="label">配置标准：</div>
                    <div class="info">{{ auxilaryInfo.auxPzbz }}</div>
                </div>
            </div>

            <div class="df">
                <div class="item">
                    <div class="label">状态：</div>
                    <div class="info">{{ auxilaryInfo.status_text }}</div>
                </div>
                <div class="item">
                    <div class="label">现有配置：</div>
                    <div class="info">{{ auxilaryInfo.auxXypz }}</div>
                </div>
                <div class="item">
                    <div class="label">存放位置：</div>
                    <div class="info">{{ auxilaryInfo.location }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">录入员账号：</div>
                    <div class="info">{{ auxilaryInfo.account }}</div>
                </div>
                <div class="item">
                    <div class="label">录入时间：</div>
                    <div class="info">{{ auxilaryInfo.recordTime }}</div>
                </div>
                <div class="item">
                    <div class="label">备注：</div>
                    <div class="info">{{ auxilaryInfo.remark }}</div>
                </div>
            </div>
        </el-card>

        <el-card>
            <div class="clearfix">
                辅助工具试验信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">上次试验日期：</div>
                    <div class="info">{{ auxilaryInfo.lastTestDate }}</div>
                </div>
                <div class="item">
                    <div class="label">下次试验日期：</div>
                    <div class="info">{{ auxilaryInfo.nextTestDate }}</div>
                </div>
            </div>
        </el-card>

        <el-card>
            <div class="clearfix">
                辅助工具详情图片
            </div>
            <div class="df-img">

                <div class="info" v-if="imgsList">
                    <div class="block" v-for="(item, index) in imgsList" :key="index">
                        <!-- <span class="demonstration">自定义</span> -->
                        <el-image :src="item" :preview-src-list="imgsList">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
        </el-card>

    </div>
</template>

<script>

import { queryAuxiliaryInfo } from '@/api/auxilInfo.js'
export default {
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            auxilaryInfo: {
                auxCode: '',
                classCode: '',
                className: '',
                auxName: '',
                auxType: '',
                auxNum: '',
                expirationDate: '',
                produceDate: null,
                lastTestDate: '',
                nextTestDate: null,
                auxPzbz: '',
                auxXypz: '',
                location: '',
                detailsImg: null,
                status: '',
                parma1: '',
                parma2: '',
                account: '',
                recordTime: null,
                remark: ''
            },

            imgsList: [],
            path: process.env.VUE_APP_URL + this.UploadPath

        }
    },
    mounted() {
        console.log(this.id)
        this.id && this.loadAuxiliaryInfo()
    },
    methods: {
        //根据id查询设备信息
        async loadAuxiliaryInfo() {
            await queryAuxiliaryInfo(this.id).then(res => {
                if (res.code === '000000') {
                    this.auxilaryInfo = res.t
                    switch (this.auxilaryInfo.status) {
                        case '00':
                            this.auxilaryInfo.status_text = '已入库'
                            break;
                        case '01':
                            this.auxilaryInfo.status_text = '已出库'
                            break;
                        case '02':
                            this.auxilaryInfo.status_text = '使用未返回'
                            break;
                        case '03':
                            this.auxilaryInfo.status_text = '报废'
                            break;
                        default:
                            this.auxilaryInfo.status_text = ''
                            break;
                    }
                    if (this.auxilaryInfo.detailsImg != null && this.auxilaryInfo.detailsImg != '') {
                        const list1 = this.auxilaryInfo.detailsImg.split(";")
                        list1.forEach(item => {
                            if (item != '' && item != null) {
                                const obj = this.path + item
                                this.imgsList.push(obj)
                            }
                        })
                    }
                }
            })
        },

    }
}

</script>


<style lang="scss" scoped>
.DeviceDetail {
    .el-card {
        position: relative;
        text-align: left;
        margin-bottom: 15px;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .clearfix {
        margin-bottom: 20px;
        font-size: 16px;
        color: #1d2129;
    }


    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }

    .df:last-child {
        margin-bottom: 0;
    }

    .df {
        display: flex;
        margin-bottom: 16px;

        .item {
            width: 33.333333%;
            display: flex;
            font-size: 14px;
            color: #4e5969;

            .label {
                margin-right: 8px;
            }

            .info {
                // width: 100%;
                float: left;
            }
        }
    }

    .df-img {
        width: 100%;
        margin-bottom: 16px;

        .info {
            width: 100%;
            // float: left;
            display: flex;

            .block {
                width: 25%;
                margin-right: 10px;

                .el-image {
                    width: 100%;
                    height: 100%;
                }
            }

        }
    }


}
</style>